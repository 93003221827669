import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { Link, useIntl, FormattedMessage } from 'gatsby-plugin-intl';

import Layout from '../../../components/Layouts/layout';
import SEO from '../../../components/seo';
// import Button from "../../../components/Button/Button"
import SubPageNav from '../../../components/SubPageNav/SubPageNav';
import SubPageBottomNav from '../../../components/SubPageBottomNav/SubPageBottomNav';

// import OBDLogo from "../../../svgs/OBDlogo-greyBg.svg";
import WebIcon from '../../../svgs/webpage-greyBg.svg';
import SpeechBubble from '../../../svgs/personSpeechBubble-greyBg.svg';
import Lightbulb from '../../../svgs/lightbulb.svg';

const HombuyersJourneyStep1Page = () => {
	const intl = useIntl();
	return (
		<Layout
			view='buyer'
			breadcrumb={{
				text: <FormattedMessage id='buyerNav.dashboard' />,
				path: '/new-home-buyer/dashboard/'
			}}
			header={<FormattedMessage id='buyerNav.steps' />}
		>
			<SEO lang={intl.locale} title={intl.formatMessage({ id: 'buyerNav.step1' })} />
			<Container>
				<Row>
					<Col>
						<SubPageNav
							links={[
								{
									text: <FormattedMessage id='buyerNav.step1' />,
									path: '/new-home-buyer/steps-in-home-buyers-journey/step-1/',
									active: true
								},
								{
									text: <FormattedMessage id='buyerNav.step2' />,
									path: '/new-home-buyer/steps-in-home-buyers-journey/step-2/'
								},
								{
									text: <FormattedMessage id='buyerNav.step3' />,
									path: '/new-home-buyer/steps-in-home-buyers-journey/step-3/'
								},
								{
									text: <FormattedMessage id='buyerNav.step4' />,
									path: '/new-home-buyer/steps-in-home-buyers-journey/step-4/'
								},
								{
									text: <FormattedMessage id='buyerNav.step5' />,
									path: '/new-home-buyer/steps-in-home-buyers-journey/step-5/'
								},
								{
									text: <FormattedMessage id='buyerNav.step6' />,
									path: '/new-home-buyer/steps-in-home-buyers-journey/step-6/'
								}
							]}
						/>
					</Col>
				</Row>

				<Row className='pageRow'>
					<Col md={12}>
						<h2>
							<FormattedMessage id='nav.step' /> 1{' '}
							<span className='thin'>
								{' '}
								<FormattedMessage id='buyerNav.step1' />{' '}
							</span>
						</h2>
					</Col>
					<Col md={12}>
						<p>
							<FormattedMessage id='homebuyersStep1.contentA' />
						</p>
						<ul>
							<li>
								<FormattedMessage id='homebuyersStep1.list01A' />
							</li>
							<li>
								<FormattedMessage id='homebuyersStep1.list01B' />
							</li>
							<li>
								<FormattedMessage id='homebuyersStep1.list01C' />
							</li>
						</ul>
						<p>
							<FormattedMessage id='homebuyersStep1.contentB' />
						</p>
					</Col>
				</Row>

				<Row className='pageRow'>
					<Col md={12}>
						<h2>
							<span className='thin'>
								{' '}
								<FormattedMessage id='nav.tipsFor' />{' '}
							</span>
							<br />
							<FormattedMessage id='homebuyersStep1.headerA' />
						</h2>
					</Col>
				</Row>

				{/* <Row className="pageRow">
        <Col md={2}>
            <OBDLogo />
        </Col>
        <Col md={10}>
          <h4 className="sm"> <FormattedMessage id="linkNames.obd" /> </h4>
          <p>
            <FormattedMessage id="homebuyersStep1.contentC" />
            <br/>
            <FormattedMessage id="homebuyersStep1.contentD" />
          </p>
          <Button linkExternal linkPath="https://obd.hcraontario.ca" text={<FormattedMessage id="linkNames.obd" />}/>
        </Col>
      </Row> */}

				<Row className='pageRow'>
					<Col md={2}>
						<WebIcon />
					</Col>
					<Col md={10}>
						<h4 className='sm'>
							{' '}
							<FormattedMessage id='homebuyersStep1.headerB' />{' '}
						</h4>
						<p>
							<FormattedMessage id='homebuyersStep1.contentE' />
						</p>
					</Col>
				</Row>

				<Row className='pageRow'>
					<Col md={2}>
						<SpeechBubble />
					</Col>
					<Col md={10}>
						<h4 className='sm'>
							{' '}
							<FormattedMessage id='homebuyersStep1.headerC' />{' '}
						</h4>
						<p>
							<FormattedMessage id='homebuyersStep1.contentF' />
						</p>
					</Col>
				</Row>
			</Container>

			<section className='fullWidthRow'>
				<Container>
					<Row align='center' style={{ marginBottom: `1rem` }}>
						<Col md={1}>
							<Lightbulb />
						</Col>

						<Col md={11}>
							<h2 style={{ marginBottom: `0` }}>
								<span className='thin'>
									{' '}
									<FormattedMessage id='nav.tipsFor' />{' '}
								</span>
								<br />
								<FormattedMessage id='homebuyersStep1.headerD' />
							</h2>
						</Col>
					</Row>
					<Row>
						<Col md={12}>
							<h4 className='sm'>
								{' '}
								<FormattedMessage id='homebuyersStep1.headerE' />{' '}
							</h4>
							<p>
								<FormattedMessage id='homebuyersStep1.contentG' />
								<br />
								<FormattedMessage id='homebuyersStep1.contentH' />
								<br />
								<FormattedMessage id='homebuyersStep1.contentI' />
							</p>
							<p>
								<FormattedMessage id='homebuyersStep1.contentIi' />{' '}
								<Link to='/new-home-buyer/before-buying-a-preconstruction-condo/'>
									<FormattedMessage id='homebuyersStep1.contentIii' />
								</Link>
								.
							</p>
						</Col>

						<Col md={12}>
							<h4 className='sm'>
								{' '}
								<FormattedMessage id='homebuyersStep1.headerF' />{' '}
							</h4>
							<p>
								<FormattedMessage id='homebuyersStep1.contentJ' />
								<br />
								<FormattedMessage id='homebuyersStep1.contentK' />
								<br />
								<FormattedMessage id='homebuyersStep1.contentL' />
							</p>
						</Col>
					</Row>
				</Container>
			</section>

			<Container>
				<Row>
					<Col md={12}>
						<SubPageBottomNav
							nextLink={{
								text: <FormattedMessage id='buyerNav.step2' />,
								path: '/new-home-buyer/steps-in-home-buyers-journey/step-2/'
							}}
						/>
					</Col>
				</Row>
			</Container>
		</Layout>
	);
};

export default HombuyersJourneyStep1Page;
